// https://typescript.nuxtjs.org/cookbook/plugins/#iii-combined-inject
import { Context, Plugin } from '@nuxt/types'
import { isFeatureEnabled } from '~/core/utils/isFeatureEnabled'

type FeatureFlag = 'sff_block_email_domains'

declare module 'vue/types/vue' {
  // this.$featureFlag inside Vue components
  interface Vue {
    $featureFlag(feature: FeatureFlag): boolean
  }
}

declare module '@nuxt/types' {
  // nuxtContext.$featureFlag
  interface Context {
    $featureFlag(feature: FeatureFlag): boolean
  }
}

declare module 'vuex/types/index' {
  // this.$featureFlag inside Vuex stores
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $featureFlag(feature: FeatureFlag): boolean
  }
}

const featureFlags: Plugin = ({ store }: Context, inject) => {
  inject('featureFlag', (feature: FeatureFlag) => {
    return isFeatureEnabled(feature, store.state.featureFlags)
  })
}

export default featureFlags
